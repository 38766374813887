var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mcma-card',{staticClass:"mcma-card-actual-progress",attrs:{"title":"Progression Actuelle","icon":"actual_progress","inline":true},scopedSlots:_vm._u([{key:"headerRightSide",fn:function(){return [_c('a-progress',{attrs:{"strokeColor":"#1890ff","percent":_vm.progression,"format":() => _vm.progression + '%',"strokeWidth":22}})]},proxy:true}])},[(
      (_vm.membre && _vm.membre.status === _vm.Constants.STATUS.IN_PROGRESS.value && _vm.progression === 100) ||
      _vm.membre.status === _vm.Constants.STATUS.EXPORTED.value ||
      _vm.membre.status === _vm.Constants.STATUS.APPROVED.value ||
      _vm.canCertify
    )?_c('mcma-card',{staticClass:"mcma-card-actual-progress-body"},[(_vm.membre.status === _vm.Constants.STATUS.IN_PROGRESS.value && _vm.canCertify)?_c('div',{staticClass:"sentence"},[_c('p',{staticClass:"principal-message inline"},[_vm._v(" Vous avez rempli toutes les "),_c('strong',[_vm._v("informations necessaires")]),_vm._v(", Merci ! "),_c('br'),_c('strong',[_vm._v(" Pensez à bien cliquer sur le bouton certifier ci dessous")]),_vm._v(" "),_c('br'),_c('strong',[_vm._v(" pour en informer votre coordonnateur qui procèdera à la validation de vos données ")]),_vm._v(" "),_c('br'),_vm._v(" et ainsi recevoir l'export de vos données par mail. ")])]):_vm._e(),(
        (_vm.membre && _vm.membre.status === _vm.Constants.STATUS.EXPORTED.value) ||
        _vm.membre.status === _vm.Constants.STATUS.APPROVED.value || _vm.membre.certified
      )?_c('div',{staticClass:"sentence"},[_c('p',{staticClass:"principal-message with-bottom"},[_vm._v(" Nous vous remercions d'avoir entièrement validé vos données. Elles sont maintenant "),_c('mcma-icon',{attrs:{"leftImage":"certified_white","color":"white","coloriseText":true,"text-transform":"capitalize","name":"certifiées"}}),_vm._v(" et transmises à l'équipe en charge de votre groupement. ")],1),_c('p',{staticClass:"secondary-message done"},[_vm._v("Vous n'avez plus d'action à réaliser.")])]):_vm._e(),(_vm.canCertify)?_c('mcma-button',{staticClass:"mt24",attrs:{"name":"Certifier le membre / exporter les données","color":"success"},on:{"click":function($event){_vm.showCertifyModal = true}}}):_vm._e()],1):_vm._e(),(_vm.canCertify)?_c('mcma-certify',{attrs:{"visible":_vm.showCertifyModal,"canCertify":_vm.canCertify,"noCertifyMessage":"Ce membre ne peut pas être certifié.","title":`Validation du membre : ${_vm.membre.nom}`,"who":"ce membre","extraMessage":"Vous ne pourrez plus modifier ni ajouter des sites, périmètres de facturation et interlocuteurs, via cette application après validation."},on:{"onCancel":_vm.closeCertifyModal,"onCertify":_vm.certify}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }